import React, { useState, useEffect } from 'react';
import { Camera, Maximize2, Minimize2, RefreshCw, Settings, AlertCircle, X, UserPlus } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Toaster, toast } from 'react-hot-toast';
import { supabase } from './lib/supabase';
import type { User } from '@supabase/supabase-js';

interface CameraFeed {
  id: number;
  name: string;
  url: string;
  status: 'online' | 'offline' | 'error';
  ip?: string;
}

const loginSchema = z.object({
  email: z.string().email('Invalid email address'),
  password: z.string().min(6, 'Password must be at least 6 characters'),
  rememberMe: z.boolean().default(false),
});

const resetSchema = z.object({
  email: z.string().email('Invalid email address'),
});

const ipSchema = z.object({
  ip: z.string().regex(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/, 'Invalid IP address format'),
});

const registerSchema = z.object({
  email: z.string().email('Invalid email address'),
  password: z.string().min(6, 'Password must be at least 6 characters'),
  confirmPassword: z.string().min(6, 'Password must be at least 6 characters'),
}).refine((data) => data.password === data.confirmPassword, {
  message: "Passwords don't match",
  path: ["confirmPassword"],
});

type LoginFormData = z.infer<typeof loginSchema>;
type ResetFormData = z.infer<typeof resetSchema>;
type IpFormData = z.infer<typeof ipSchema>;
type RegisterFormData = z.infer<typeof registerSchema>;

function App() {
  const [cameras, setCameras] = useState<CameraFeed[]>([
    { id: 1, name: 'Front Entrance', url: 'https://example.com/camera1', status: 'online', ip: '192.168.31.115' },
    { id: 2, name: 'Back Door', url: 'https://example.com/camera2', status: 'online' },
    { id: 3, name: 'Parking Lot', url: 'https://example.com/camera3', status: 'error' },
    { id: 4, name: 'Side Gate', url: 'https://example.com/camera4', status: 'offline' },
  ]);
  const [fullscreenCamera, setFullscreenCamera] = useState<number | null>(null);
  const [selectedCamera, setSelectedCamera] = useState<number | null>(null);
  const [showSettings, setShowSettings] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user ?? null);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user ?? null);
    });

    return () => subscription.unsubscribe();
  }, []);

  const loginForm = useForm<LoginFormData>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      rememberMe: false,
    },
  });

  const resetForm = useForm<ResetFormData>({
    resolver: zodResolver(resetSchema),
  });

  const ipForm = useForm<IpFormData>({
    resolver: zodResolver(ipSchema),
  });

  const registerForm = useForm<RegisterFormData>({
    resolver: zodResolver(registerSchema),
  });

  const toggleFullscreen = (cameraId: number) => {
    setFullscreenCamera(fullscreenCamera === cameraId ? null : cameraId);
  };

  const refreshCamera = (cameraId: number) => {
    console.log(`Refreshing camera ${cameraId}`);
  };

  const openSettings = (cameraId: number) => {
    if (!user) {
      setShowLogin(true);
      return;
    }
    setSelectedCamera(cameraId);
    setShowSettings(true);
    const camera = cameras.find(c => c.id === cameraId);
    if (camera?.ip) {
      ipForm.setValue('ip', camera.ip);
    }
  };

  const onLoginSubmit = async (data: LoginFormData) => {
    try {
      setLoading(true);
      const { error } = await supabase.auth.signInWithPassword({
        email: data.email,
        password: data.password,
      });

      if (error) {
        if (error.message === 'Invalid login credentials') {
          throw new Error('Invalid email or password. Please try again.');
        }
        throw error;
      }

      toast.success('Successfully logged in!');
      setShowLogin(false);
      loginForm.reset();
    } catch (error: any) {
      toast.error(error.message || 'Failed to login');
      loginForm.setValue('password', '');
    } finally {
      setLoading(false);
    }
  };

  const onRegisterSubmit = async (data: RegisterFormData) => {
    try {
      setLoading(true);
      const { error } = await supabase.auth.signUp({
        email: data.email,
        password: data.password,
      });

      if (error) throw error;

      toast.success('Registration successful! You can now log in.');
      setShowRegister(false);
      registerForm.reset();
    } catch (error: any) {
      toast.error(error.message || 'Failed to register');
    } finally {
      setLoading(false);
    }
  };

  const onResetSubmit = async (data: ResetFormData) => {
    try {
      setLoading(true);
      const { error } = await supabase.auth.resetPasswordForEmail(data.email, {
        redirectTo: `${window.location.origin}/reset-password`,
      });

      if (error) throw error;

      toast.success('Password reset instructions sent to your email!');
      setShowResetPassword(false);
      resetForm.reset();
    } catch (error: any) {
      toast.error(error.message || 'Failed to send reset instructions');
    } finally {
      setLoading(false);
    }
  };

  const onLogout = async () => {
    try {
      setLoading(true);
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      toast.success('Successfully logged out!');
    } catch (error: any) {
      toast.error(error.message || 'Failed to logout');
    } finally {
      setLoading(false);
    }
  };

  const onIpSubmit = async (data: IpFormData) => {
    if (selectedCamera === null) return;
    
    try {
      const { error } = await supabase
        .from('cameras')
        .update({ 
          ip: data.ip,
          url: `http://${data.ip}/video`
        })
        .eq('id', selectedCamera)
        .single();

      if (error) throw error;

      setCameras(prev => prev.map(camera => 
        camera.id === selectedCamera 
          ? { 
              ...camera, 
              ip: data.ip,
              url: `http://${data.ip}/video`,
              status: 'online'
            }
          : camera
      ));

      try {
        const response = await fetch(`http://${data.ip}/video`, { method: 'HEAD' });
        if (!response.ok) {
          toast.warning('Camera URL is accessible but might not be streaming correctly');
        }
      } catch (err) {
        toast.warning('Could not verify camera stream. Please check the IP address and camera settings');
      }

      toast.success('Camera IP updated successfully!');
      setShowSettings(false);
      ipForm.reset();
    } catch (error: any) {
      toast.error(error.message || 'Failed to update camera IP');
    }
  };

  const testCameraConnection = async (ip: string) => {
    try {
      const response = await fetch(`http://${ip}/video`, { method: 'HEAD' });
      return response.ok;
    } catch {
      return false;
    }
  };

  const Modal = ({ children, onClose }: { children: React.ReactNode; onClose: () => void }) => (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <X size={20} />
        </button>
        {children}
      </div>
    </div>
  );

  const LoginModal = () => (
    <Modal onClose={() => setShowLogin(false)}>
      <h2 className="text-xl font-bold mb-4">Login</h2>
      <form onSubmit={loginForm.handleSubmit(onLoginSubmit)} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            {...loginForm.register('email')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            disabled={loading}
          />
          {loginForm.formState.errors.email && (
            <p className="mt-1 text-sm text-red-600">{loginForm.formState.errors.email.message}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Password</label>
          <input
            type="password"
            {...loginForm.register('password')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            disabled={loading}
          />
          {loginForm.formState.errors.password && (
            <p className="mt-1 text-sm text-red-600">{loginForm.formState.errors.password.message}</p>
          )}
        </div>
        <div className="flex items-center justify-between">
          <label className="flex items-center">
            <input
              type="checkbox"
              {...loginForm.register('rememberMe')}
              className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              disabled={loading}
            />
            <span className="ml-2 text-sm text-gray-600">Remember me</span>
          </label>
          <button
            type="button"
            onClick={() => {
              setShowLogin(false);
              setShowResetPassword(true);
            }}
            className="text-sm text-blue-600 hover:text-blue-700"
            disabled={loading}
          >
            Forgot password?
          </button>
        </div>
        <div className="space-y-2">
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={loading}
          >
            {loading ? 'Logging in...' : 'Login'}
          </button>
          <button
            type="button"
            onClick={() => {
              setShowLogin(false);
              setShowRegister(true);
            }}
            className="w-full flex items-center justify-center gap-2 bg-gray-100 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          >
            <UserPlus size={18} />
            Register New Account
          </button>
        </div>
      </form>
    </Modal>
  );

  const RegisterModal = () => (
    <Modal onClose={() => setShowRegister(false)}>
      <h2 className="text-xl font-bold mb-4">Register New Account</h2>
      <form onSubmit={registerForm.handleSubmit(onRegisterSubmit)} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            {...registerForm.register('email')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            disabled={loading}
          />
          {registerForm.formState.errors.email && (
            <p className="mt-1 text-sm text-red-600">{registerForm.formState.errors.email.message}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Password</label>
          <input
            type="password"
            {...registerForm.register('password')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            disabled={loading}
          />
          {registerForm.formState.errors.password && (
            <p className="mt-1 text-sm text-red-600">{registerForm.formState.errors.password.message}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Confirm Password</label>
          <input
            type="password"
            {...registerForm.register('confirmPassword')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            disabled={loading}
          />
          {registerForm.formState.errors.confirmPassword && (
            <p className="mt-1 text-sm text-red-600">{registerForm.formState.errors.confirmPassword.message}</p>
          )}
        </div>
        <div className="space-y-2">
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={loading}
          >
            {loading ? 'Registering...' : 'Register'}
          </button>
          <button
            type="button"
            onClick={() => {
              setShowRegister(false);
              setShowLogin(true);
            }}
            className="w-full text-sm text-blue-600 hover:text-blue-700"
          >
            Already have an account? Login
          </button>
        </div>
      </form>
    </Modal>
  );

  const ResetPasswordModal = () => (
    <Modal onClose={() => setShowResetPassword(false)}>
      <h2 className="text-xl font-bold mb-4">Reset Password</h2>
      <form onSubmit={resetForm.handleSubmit(onResetSubmit)} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            {...resetForm.register('email')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            disabled={loading}
          />
          {resetForm.formState.errors.email && (
            <p className="mt-1 text-sm text-red-600">{resetForm.formState.errors.email.message}</p>
          )}
        </div>
        <button
          type="submit"
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={loading}
        >
          {loading ? 'Sending...' : 'Send Reset Instructions'}
        </button>
      </form>
    </Modal>
  );

  const SettingsModal = () => {
    const camera = cameras.find(c => c.id === selectedCamera);
    if (!camera) return null;

    return (
      <Modal onClose={() => setShowSettings(false)}>
        <h2 className="text-xl font-bold mb-4">Camera Settings - {camera.name}</h2>
        <form onSubmit={ipForm.handleSubmit(onIpSubmit)} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">IP Address</label>
            <input
              type="text"
              {...ipForm.register('ip')}
              placeholder="192.168.1.1"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            {ipForm.formState.errors.ip && (
              <p className="mt-1 text-sm text-red-600">{ipForm.formState.errors.ip.message}</p>
            )}
          </div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Save Changes
          </button>
        </form>
      </Modal>
    );
  };

  const CameraCard = ({ camera }: { camera: CameraFeed }) => {
    const isFullscreen = fullscreenCamera === camera.id;
    const [streamError, setStreamError] = useState(false);
    
    useEffect(() => {
      if (camera.ip) {
        testCameraConnection(camera.ip)
          .then(isConnected => {
            if (!isConnected) setStreamError(true);
          });
      }
    }, [camera.ip]);

    return (
      <div className={`relative bg-white rounded-lg shadow-lg overflow-hidden
        ${isFullscreen ? 'col-span-2 row-span-2' : ''}`}>
        <div className="absolute top-0 left-0 right-0 bg-gray-900/75 text-white p-3 flex justify-between items-center">
          <div className="flex items-center gap-2">
            <Camera size={18} />
            <span className="font-medium">{camera.name}</span>
            {camera.ip && (
              <span className="text-sm text-gray-300">({camera.ip})</span>
            )}
          </div>
          <div className="flex items-center gap-2">
            {camera.status === 'online' && !streamError && (
              <span className="flex items-center gap-1">
                <div className="w-2 h-2 rounded-full bg-green-500"></div>
                <span className="text-sm">Live</span>
              </span>
            )}
            {(camera.status === 'error' || streamError) && (
              <span className="flex items-center gap-1 text-yellow-400">
                <AlertCircle size={16} />
                <span className="text-sm">Error</span>
              </span>
            )}
            {camera.status === 'offline' && !streamError && (
              <span className="flex items-center gap-1 text-red-400">
                <AlertCircle size={16} />
                <span className="text-sm">Offline</span>
              </span>
            )}
          </div>
        </div>
        
        <div className="aspect-video bg-gray-900 relative">
          {camera.ip ? (
            <img 
              src={`http://${camera.ip}/video`}
              alt={camera.name}
              className="w-full h-full object-cover"
              onError={() => setStreamError(true)}
            />
          ) : (
            <img 
              src="https://images.unsplash.com/photo-1577017040065-650ee4d43339?auto=format&fit=crop&w=800&q=80" 
              alt={camera.name}
              className="w-full h-full object-cover"
            />
          )}
          {streamError && (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-900/75 text-white">
              <div className="text-center">
                <AlertCircle size={32} className="mx-auto mb-2" />
                <p>Cannot connect to camera stream</p>
                <p className="text-sm text-gray-400">Please check camera settings</p>
              </div>
            </div>
          )}
        </div>

        <div className="absolute bottom-0 left-0 right-0 bg-gray-900/75 text-white p-2 flex justify-end gap-2">
          <button 
            onClick={() => {
              refreshCamera(camera.id);
              setStreamError(false);
            }}
            className="p-1.5 rounded-full hover:bg-white/20 transition-colors"
            title="Refresh feed"
          >
            <RefreshCw size={18} />
          </button>
          <button 
            onClick={() => toggleFullscreen(camera.id)}
            className="p-1.5 rounded-full hover:bg-white/20 transition-colors"
            title={isFullscreen ? 'Exit fullscreen' : 'Fullscreen'}
          >
            {isFullscreen ? <Minimize2 size={18} /> : <Maximize2 size={18} />}
          </button>
          <button 
            onClick={() => openSettings(camera.id)}
            className="p-1.5 rounded-full hover:bg-white/20 transition-colors"
            title="Camera settings"
          >
            <Settings size={18} />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="max-w-7xl mx-auto">
        <header className="mb-6 flex justify-between items-center">
          <h1 className="text-2xl font-bold text-gray-900 flex items-center gap-2">
            <Camera />
            Security Camera Dashboard
          </h1>
          {user ? (
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-600">{user.email}</span>
              <button
                onClick={onLogout}
                className="text-sm text-red-600 hover:text-red-700 disabled:opacity-50"
                disabled={loading}
              >
                {loading ? 'Logging out...' : 'Logout'}
              </button>
            </div>
          ) : (
            <button
              onClick={() => setShowLogin(true)}
              className="text-sm text-blue-600 hover:text-blue-700"
            >
              Login
            </button>
          )}
        </header>

        <div className={`grid gap-6 
          ${fullscreenCamera ? 'grid-cols-2 grid-rows-2' : 'grid-cols-2 md:grid-cols-2 lg:grid-cols-2'}`}>
          {cameras.map(camera => (
            <CameraCard key={camera.id} camera={camera} />
          ))}
        </div>
      </div>

      <Toaster position="top-right" />
      {showLogin && <LoginModal />}
      {showRegister && <RegisterModal />}
      {showResetPassword && <ResetPasswordModal />}
      {showSettings && <SettingsModal />}
    </div>
  );
}

export default App;